





















import { defineComponent, ref } from '@vue/composition-api'
import { techList } from '../constants/index'

export default defineComponent({
  name: 'AsyncTechList',
  props: {},
  setup() {
    const current = ref<number>(0)
    const handleMouse = (index: number) => {
      current.value = index
    }
    return {
      current,
      techList,
      handleMouse
    }
  }
})
